import React, { Component } from "react";
import { Button, FormGroup, FormControl, FormLabel ,Image } from "react-bootstrap";
import "./Login.css";
import Cookies from 'js-cookie'

export const getAccessToken = () => Cookies.get('access_token')
export const getRefreshToken = () => Cookies.get('refresh_token')
export const isAuthenticated = () => !!getAccessToken()


export default class Login extends Component {
  constructor(props) {
    super(props);

    this.state = {
      user_id: "",
      name: "",
      email: "",
      password: "",
      password_confirmation: "",
    };
  }

componentDidMount() {
  this.updateWindowDimensions();
  window.addEventListener("resize", this.updateWindowDimensions.bind(this));

  // try {
  //   fetch('https://api.ptncs.com/bot/checkprice/demosystemgudang/demo?origin=CGK317301&destination=KDS331608',
  //   {
  //     method: 'get',
  //     headers: {
  //       "Accept":"*/*",
  //       'Accept-Encoding': 'gzip, deflate, br',
  //       'Connection': 'keep-alive',
  //       'Host': 'api.ptncs.com',
  //       'Cache-Control': 'no-cache',
  //       'User-Agent': 'PostmanRuntime/7.26.5',
  //       'Postman-Token': '25e60226-36b0-4f34-a3a9-9758ec4dfe8d',

  //     },
  //     //body: JSON.stringify(this.state), 
  //   })
  //   .then(response =>  response.json())
  //   .then(resData => {
  //     console.log(JSON.stringify(resData));
  //     if(resData.message==="success"){

  //     }

  //   });

  //   return true
  //   } catch (error) {
  //     global.swal(error);
  //     return false
  //   }

}

componentWillUnmount() {
    window.removeEventListener("resize", this.updateWindowDimensions.bind(this));
}

updateWindowDimensions() {
    this.setState({ width: window.innerWidth, height: window.innerHeight });
}

  validateForm() {
    return this.state.user_id.length > 0 
    && this.state.name.length > 0
    && this.state.email.length > 0
    && this.state.password.length > 0
    && this.state.password_confirmation.length > 0;
  }

  handleChange = event => {
    this.setState({
      [event.target.id]: event.target.value
    });
  }

  handleSubmit = event => {
    event.preventDefault();
    
    try {
      fetch(global.variable.LINK_API + 'auth/signup',
      {
        method: 'post',
        headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
        },
        body: JSON.stringify(this.state), 
      })
      .then(response =>  response.json())
      .then(resData => {
        console.log(JSON.stringify(resData));
        if(resData.message==="success"){

          var span = document.createElement("span");
          span.innerHTML = "Berhasil Registrasi \nAnda telah terdaftar pada sistem. </br></br><strong>Silahkan melakukan aktivasi akun anda melalui email yang anda daftarkan.</strong>";

          global.swal({
              title: "Success", 
              content: span,
              icon: "success" 
          }).then((value) => {
            window.location.href="/";
          });;

          
          //global.swal(Cookies.get('access_token'));
        }else{
          let msgerr = "";
          if(resData.errors){
            let count = Object.keys(resData.errors).length;
            for(let i=0; i<count; i++){
              msgerr = msgerr + resData.errors[Object.keys(resData.errors)[i]] + "\n";
            }
          }
          global.swal(msgerr)
        }
        
      })
    

      return true
    } catch (error) {
      global.swal(error);
      return false
    }
  }

  back(){
    
    window.location.href="/";
  }

  render() {
    return (
      <div className="Login" style={{backgroundImage: `url(${require(`./assets/images/background.png`)})`, backgroundPosition: 'center',  height:this.state.height,
        backgroundRepeat: 'no-repeat', backgroundSize: 'cover',}}>
        <div className="text-center" style={{background:'transparent', border: '0px' ,marginTop:-50, marginBottom:30}}>
        <Image alt="Bukti transfer" style={{width: 500, background:'transparent', }} src={require('./assets/images/logo_ppdb_online_domsav.png')} />
       </div>
        <form onSubmit={this.handleSubmit}>
          <FormGroup controlId="user_id">
            <FormLabel style={{fontSize:18}}>Registrasi</FormLabel>
          </FormGroup>
          <FormGroup controlId="name" >
            <FormLabel >Masukan Nama</FormLabel >
            <FormControl
              type="text"
              name="name"
              value={this.state.name}
              onChange={this.handleChange}
            />
          </FormGroup>
          <FormGroup controlId="email" >
            <FormLabel >Email Aktif</FormLabel >
            <FormControl
              type="email"
              name="email"
              value={this.state.email}
              onChange={this.handleChange}
            />
          </FormGroup>
          <FormGroup controlId="user_id" >
            <FormLabel >Masukan User ID (Membuat Sendiri)</FormLabel >
            <FormControl
              autoFocus
              type="text"
              name="user_id"
              value={this.state.user_id}
              onChange={this.handleChange}
            />
          </FormGroup>
          <FormGroup controlId="password" >
            <FormLabel >Password (Membuat Sendiri)</FormLabel >
            <FormControl
              name="password"
              value={this.state.password}
              onChange={this.handleChange}
              type="password"
            />
          </FormGroup>
          <FormGroup controlId="password_confirmation" >
            <FormLabel >Ketik Ulang Password</FormLabel >
            <FormControl
              name="password_confirmation"
              value={this.state.password_confirmation}
              onChange={this.handleChange}
              type="password"
            />
          </FormGroup>
          {/* <FormGroup controlId="user_id">
            <FormLabel className="text-center" style={{fontSize:18,color:'red'}}>Ingatlah User ID dan Password yang Anda buat!</FormLabel>
          </FormGroup> */}
          <Button
            block
            disabled={!this.validateForm()}
            type="submit"
          >
            Submit
          </Button>

          
          <Button
            block
            onClick={this.back}
            type="button">
            Back
          </Button>
        </form>
      </div>
    );
  }
}