import React, { Component } from 'react';
import {Form, Breadcrumb, Button} from 'react-bootstrap';

class Cetak extends Component {

  constructor(props){
    super(props);

    this.state={
        CB_JALUR : '',
        val_jalur : '',
        CB_JALUR2 : '',
        val_jalur2 : '',
    }
  }

  componentDidMount() {
    // fetch(global.variable.LINK_API + 'student/flow/4',
    // {
    //   method: 'get',
    //   headers: {
    //       Accept: "application/json",
    //       "Content-Type": "application/json",
    //       'Authorization': 'Bearer '+ global.cookies.get('access_token'), 
    //   },
    //   //body: JSON.stringify(data)
    // })
    // .then(response =>  response.json())
    // .then(resData => {
    //   if(resData.message==="Success"){
    //     //localStorage.setItem('DATA_SISWA', JSON.stringify(resData.data));

    //     this.setState({ CB_JALUR: [...this.state.CB_JALUR, resData.data]})

        

    //   }
    //   console.log(resData);
       
    // })

    
    fetch(global.variable.LINK_API + 'student/flow/5',
        {
          method: 'get',
          headers: {
              Accept: "application/json",
              "Content-Type": "application/json",
              'Authorization': 'Bearer '+ global.cookies.get('access_token'), 
          },
          //body: JSON.stringify(data)
        })
        .then(response =>  response.json())
        .then(resData => {
          if(resData.message==="Success"){
            
            this.setState({ CB_JALUR2: [...this.state.CB_JALUR2, resData.data]})


          }
          console.log(resData);
          
        })

        fetch(global.variable.LINK_API + 'student/flow_offline?currentyear=1',
        {
          method: 'get',
          headers: {
              Accept: "application/json",
              "Content-Type": "application/json",
              'Authorization': 'Bearer '+ global.cookies.get('access_token'),
          },
          //body: JSON.stringify(data)
        })
        .then(response =>  response.json())
        .then(resData => {
          if(resData.message==="Success"){
            //localStorage.setItem('DATA_SISWA', JSON.stringify(resData.data));

            this.setState({CB_JALUR : [...this.state.CB_JALUR, resData.data], is_show: true});

          }
          console.log(resData);
          
        })

    fetch(global.variable.LINK_API + 'parameter',
    {
      method: 'get',
      headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          'Authorization': 'Bearer '+ global.cookies.get('access_token'), 
      },
      //body: JSON.stringify(data)
    })
    .then(response =>  response.json())
    .then(resData => {
      if(resData.message==="Success"){
        localStorage.setItem('DATA_PARAMETER', JSON.stringify(resData.data));
      }
      console.log(JSON.parse(localStorage.getItem('DATA_PARAMETER')));
       
    })
  }

  createSelectItems() {
    let items = []; 
    let jalur = this.state.CB_JALUR[0];
    console.log(this.state.CB_JALUR[0]);
    
    
    for (let i = 0; i < jalur.length; i++) {             
         items.push(<option key={i} value={jalur[i].id+'-'+jalur[i].fullname}>{jalur[i].id+' - '+jalur[i].fullname}</option>);   
    }
    return items;
  }
  


  createSelectItems2() {
    let items = []; 
    let jalur = this.state.CB_JALUR2[0];     
    
    
    for (let i = 0; i < jalur.length; i++) {             
         items.push(<option key={i} value={jalur[i].id+'-'+jalur[i].fullname}>{jalur[i].id+' - '+jalur[i].fullname}</option>);   
    }
    return items;
  
}

  onDropdownSelected(e) {
    console.log("THE VAL", e.target.value);
    this.setState({[e.target.name]: e.target.value});
  }

  handleSubmit = event => {
    event.preventDefault();
    localStorage.setItem('DATA_JALUR', this.state.val_jalur);
    
    if(this.state.val_jalur===""){
      global.swal("Info", "Harap pilih siswa", "info");
    }else{
      window.location.href="/cetakpendaftarandetail";
    }
  }

  handleSubmit2 = event => {
    event.preventDefault();
    localStorage.setItem('DATA_JALUR', this.state.val_jalur2);
    if(this.state.val_jalur2===""){
      global.swal("Info", "Harap pilih siswa", "info");
    }else{
      window.location.href="/cetakwawancara";
    }
  }

  render() {
    return (
      <div className="content-wrapper">
          <section className="content-header">
              <div className="row">
                  <div className="col-md-12">
                      <div className="box">

                      <div className="divContainter">
                        <Breadcrumb>
                          <Breadcrumb.Item href="/home">Home</Breadcrumb.Item>
                          <Breadcrumb.Item active>Cetak</Breadcrumb.Item>
                        </Breadcrumb>

                        <form onSubmit={this.handleSubmit}>
                          <Form.Group controlId="exampleForm.ControlSelect1">
                            <Form.Label>Cetak Bukti Pendaftaran</Form.Label>
                            <Form.Control as="select" name="val_jalur" onChange={this.onDropdownSelected.bind(this)}>
                              <option value="">- Pilih Siswa -</option>
                              {this.state.CB_JALUR?
                                this.createSelectItems()
                              : ''}
                              
                            </Form.Control>
                          </Form.Group>

                          <Button
                            block
                            type="submit">
                            Submit
                          </Button>
                        </form>

                        <form onSubmit={this.handleSubmit2}>
                          <Form.Group controlId="exampleForm.ControlSelect1" style={{marginTop:30}}>
                            <Form.Label>Cetak Undangan</Form.Label>
                            <Form.Control as="select" name="val_jalur2" onChange={this.onDropdownSelected.bind(this)}>
                              <option value="">- Pilih Siswa -</option>
                              {this.state.CB_JALUR2?
                                this.createSelectItems2()
                              : ''}
                              
                            </Form.Control>
                          </Form.Group>

                          <Button
                            block
                            type="submit">
                            Submit
                          </Button>
                        </form>
                      </div>


                      </div>
                  </div>
              </div>
          </section>
      </div>
      
    );
  }
}

export default Cetak;