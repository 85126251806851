import React, { Component } from 'react';
import {Form, Breadcrumb, Button} from 'react-bootstrap';
import 'moment/locale/id.js';
import 'rc-datepicker/lib/style.css';
import ReactToPrint from "react-to-print";
const moment = require('moment');

// class ComponentToPrint extends React.Component {

//   constructor(props){
//     super(props);

//     this.state={
//         student_id : '',
//         is_show : true,
//         image_64 : '',
//     }
//   }

//   componentDidMount() {
//     this.setState({student_id: localStorage.getItem('DATA_JALUR').split('-')[0]});
    
//   }

//   render() {
//     return (

//       this.state.is_show?
//         <div id="divPrint" style={{width:810, border: '0px solid black'}}>
//             <div style={{alignItems: 'center', alignContent: 'center', justifyContent: 'center' ,textAlign: 'center',}}>
//                 <img src={require("../assets/images/kop_surat.png")} alt="kop" style={{width:800}}/>
//             </div>
//             <div style={{alignItems: 'center', alignContent: 'center', justifyContent: 'center', fontSize:20, marginTop:20, textAlign: 'center', }}>
//               <label style={{textDecorationLine: 'underline'}}>BUKTI PENDAFTARAN</label>
//             </div>

//             <div className="rowHorizontal" style={{alignContent:'right', alignItems: 'right', justifyContent: 'right', }}>
//               <div style={{width:160, height:120, border: '1px solid black', margin:20, marginLeft:50}}>
//                 <div style={{alignItems: 'center', alignContent: 'center', justifyContent: 'center', fontSize:15, marginTop:20, textAlign: 'center', textDecorationLine: 'underline'}}>
//                   <label>Jenis Formulir</label>
//                 </div>
//                 <hr style={{border:'none', height:1, backgroundColor:'black'}}/>
//                 <div style={{alignItems: 'center', alignContent: 'center', justifyContent: 'center', fontSize:20, marginTop:10, textAlign: 'center'}}>
//                   {JSON.parse(localStorage.getItem('reg1')).registration_type}
//                 </div>
//               </div>
//             </div>

//             <div className="rowHorizontal">
//               <div style={{width:200, margin:5, marginLeft:50}}>
//                 <label>No. Pendaftaran</label>
//               </div>
//               <div style={{margin:5}}>
//                 <label>: 123</label>
//               </div>
//             </div>

//         </div>
//         : ''
//     );
//   }
// }

class Pendaftaran extends Component {

  constructor(props){
    super(props);

    this.state={
    }
    
  }

  componentDidMount() {
    if(localStorage.getItem('reg7')){
      // this.setState(JSON.parse(localStorage.getItem('reg7')));
    }
    // console.log(JSON.parse(localStorage.getItem('reg7')))

    localStorage.getItem('IS_OFFLINE')
    // console.log(JSON.parse(localStorage.getItem('IS_OFFLINE')?localStorage.getItem('IS_OFFLINE'):0));
  }

  onChange = e => {

    const nom = (e.target.value)
    if(nom>100){
      global.swal("Error", "Nilai tidak boleh lebih dari 100", "info")
      this.setState({ [e.target.name]: ""  })
    }else{
      this.setState({ [e.target.name]: e.target.name +"_"+ e.target.value  })
    }
    

  }

  onDropdownSelected(e) {
    this.setState({[e.target.name]: e.target.value});
  }

  createScore() {
    let itemsClass = [];
    let dataClass = JSON.parse(localStorage.getItem('DATA_PARAMETER')).prmClassroom;
    let items = [];
    let subject = JSON.parse(localStorage.getItem('DATA_PARAMETER')).prmSubject;


    for (let i = 0; i < subject.length; i++) {             
         items.push(
          <Form.Group controlId="exampleForm.ControlInput1" key={i}>
            <div className='rowHorizontal'>
              <Form.Label className="col-md-3" style={{width: 200, margin:5}}>{subject[i].name} </Form.Label>
              <Form.Control className="col-md-3"
                type="Text"
                name={subject[i].id+"_1"}
                maxLength="3"
                onChange={this.onChange}
                style={{width: 120, margin:5}}
                placeholder="Semester 1"
                required />
              <Form.Control className="col-md-3"
                type="Text"
                name={subject[i].id+"_2"}
                maxLength="3"
                onChange={this.onChange}
                style={{width: 120, margin:5}}
                placeholder="Semester 2"
                required />
            </div>
          </Form.Group>
          
          );   
    }

    for(let j=0; j< dataClass.length; j++){
      itemsClass.push(
        console.log(dataClass[j].name),
        <Form.Label className="col-md-3" style={{width: 200, margin:5, fontSize: 20}} key={j}>{dataClass[j].name} </Form.Label>,
        items
        
      );
    }
    return itemsClass;
  }

  handleSubmit = event => {
    event.preventDefault();
    event.stopPropagation();    
    //console.log("state 4 " + JSON.stringify(this.state));
    // console.log(this.state)

    let reg1 = JSON.parse(localStorage.getItem('reg1'));
    
    let stateData = Object.keys(this.state).map((key) => this.state[key]);
    let item =[];
    let total_smst1_1 = 0;
    let total_smst2_1 = 0;
    let total_kkm1_1 = 10;
    let total_kkm2_1 = 10;
    let total_smst1_2 = 0;
    let total_smst2_2 = 0;
    let total_kkm1_2 = 10;
    let total_kkm2_2 = 10;

    let total_mtk = 0;
    let total_bind = 0;
    let total_ipa = 0;

    for (let i = 0; i < stateData.length; i++) { 
      let splitData = stateData[i].split('_');
      let items =  Object.create({});
      items.class_id = splitData[0];
      items.subject_id = splitData[1];
      items.semester = splitData[2];
      items.score = splitData[3];

      if(reg1.registration_type==='JR'){
        if(splitData[0]==='1'){
          if(splitData[2]==='1'){
            total_smst1_1 = total_smst1_1 + parseInt(splitData[3]);
          }else if(splitData[2]==='kkm1'){
            total_kkm1_1 = total_kkm1_1 + parseInt(splitData[3]);
          }else if(splitData[2]==='2'){
            total_smst2_1 = total_smst2_1 + parseInt(splitData[3]);
          }else if(splitData[2]==='kkm2'){
            total_kkm2_1 = total_kkm2_1 + parseInt(splitData[3]);
          }
        }else{
          if(splitData[2]==='1'){
            total_smst1_2 = total_smst1_2 + parseInt(splitData[3]);
          }else if(splitData[2]==='kkm1'){
            total_kkm1_2 = total_kkm1_2 + parseInt(splitData[3]);
          }else if(splitData[2]==='2'){
            total_smst2_2 = total_smst2_2 + parseInt(splitData[3]);
          }else if(splitData[2]==='kkm2'){
            total_kkm2_2 = total_kkm2_2 + parseInt(splitData[3]);
          }
        }
      }
      if(splitData[3]<10){
        global.swal("Error", "Nilai tidak boleh kurang dari 10", "info")
        return false;
      }

      if(reg1.registration_type==='JN'){
        if(splitData[1]==1){
          total_mtk = total_mtk+parseInt(splitData[3]);
        }
        if(splitData[1]==2){
          total_bind = total_bind+parseInt(splitData[3]);
        }
        if(splitData[1]==6){
          total_ipa = total_ipa+parseInt(splitData[3]);
        }
      }

      item.push(items);
    }
    
    let jr_ok = 1;
    if(reg1.registration_type==='JR'){
      // console.log('smst 1 '+ total_smst1_1)
      // console.log('kkm 1 '+ total_kkm1_1)
      // console.log('smst 2 '+ total_smst2_1)
      // console.log('kkm 2 '+ total_kkm2_1)
      if(total_smst1_1<total_kkm1_1){jr_ok = 0;}
      if(total_smst2_1<total_kkm2_1){jr_ok = 0;}
      if(total_smst1_2<total_kkm1_2){jr_ok = 0;}
      if(total_smst2_2<total_kkm2_2){jr_ok = 0;}
      // console.log('smst 1 '+ total_smst1_2)
      // console.log('kkm 1 '+ total_kkm1_2)
      // console.log('smst 2 '+ total_smst2_2)
      // console.log('kkm 2 '+ total_kkm2_2)
      
      if(jr_ok===0){
        global.swal("Error", "Nilai tidak mencukupi untuk masuk ke Jalur Raport", "info")
        return false;
      }
    }
    // console.log('jr' + jr_ok);


    if(reg1.registration_type==='JN'){
      console.log(total_mtk)
      console.log(total_mtk/4)
      if((total_mtk/4)<85){
        global.swal("Error", "Calon peserta didik dimohon mendaftar melalui jalur lain, karena nilai Matematika di bawah 85", "info")
        return false;
      }
      if((total_bind/4)<85){
        global.swal("Error", "Calon peserta didik dimohon mendaftar melalui jalur lain, karena nilai Bhs. Indonesia di bawah 85", "info")
        return false;
      }
      if((total_ipa/4)<85){
        global.swal("Error", "Calon peserta didik dimohon mendaftar melalui jalur lain, karena nilai IPA di bawah 85", "info")
        return false;
      }
    }
    // console.log(JSON.stringify(item));
    
    localStorage.setItem('reg7', JSON.stringify(item));

    let reg2 = JSON.parse(localStorage.getItem('reg2'));
    let reg3 = JSON.parse(localStorage.getItem('reg3'));
    let reg4 = JSON.parse(localStorage.getItem('reg4'));
    let reg5 = JSON.parse(localStorage.getItem('reg5'));
    let reg6 = JSON.parse(localStorage.getItem('reg6'));
    let reg7 = JSON.parse(localStorage.getItem('reg7'));

    let statereg4 = JSON.parse(localStorage.getItem('statereg4'));

    const result = {};
    Object.keys(reg1).forEach(key => result[key] = reg1[key]);
    Object.keys(reg2).forEach(key => result[key] = reg2[key]);
    Object.keys(reg3).forEach(key => result[key] = reg3[key]);
    result['guardian'] = reg4;
    result['sibling'] = reg5;
    result['achieve'] = reg6;
    result['score'] = reg7;
    result['parent_status_id'] = statereg4.parent_status_id;
    result['both_parent_work'] = statereg4.both_parent_work;
    result['is_offline'] = localStorage.getItem('IS_OFFLINE')?localStorage.getItem('IS_OFFLINE'):0;

    console.log(JSON.stringify(result));
    
    const data = { data:JSON.stringify(result) }

    global.swal({
      text: 'Apakah anda yakin akan disubmit?',
      closeOnEsc: false,
      closeOnClickOutside: false,
      buttons: [
        'Cancel',
        {text: 'Submit', 
        closeModal: false,}
      ],
    })
    .then((confirm) => {
      let errorMessage =  { code : 403, message : 'cancel' };
      if (!confirm) throw errorMessage;
      return fetch(global.variable.LINK_API + 'student/store',
      {
        method: 'post',
        headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            'Authorization': 'Bearer '+ global.cookies.get('access_token'), 
        },
        body: JSON.stringify(data), 
      });
    })
    .then(response =>  response.json())
    .then(resData => {
      if(resData.message==="Success"){
        console.log(resData);
        localStorage.removeItem('reg1');
        localStorage.removeItem('reg2');
        localStorage.removeItem('reg3');
        localStorage.removeItem('reg4');
        localStorage.removeItem('statereg4');
        localStorage.removeItem('reg5');
        localStorage.removeItem('reg6');
        localStorage.removeItem('reg7');
        global.swal("Success", resData.message, "success", )
        .then((value) => {
          window.location.href="/pendaftaran";
        });
      }else{
        global.swal("Error", resData.error, "info")
      }
    })
    .catch(err => {
      if (err) {
        if(err.code!==403){
          global.swal("Error", "Koneksi ke server gagal.", "info")
        }
      } else {
        global.swal.stopLoading();
        global.swal.close();
      }
    });


    /*const data = { data:JSON.stringify(result) }

    fetch(global.variable.LINK_API + 'student/store',
    {
      method: 'post',
      headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          'Authorization': 'Bearer '+ global.cookies.get('access_token'), 
      },
      body: JSON.stringify(data), 
    })
    .then(response =>  response.json())
    .then(resData => {
      if(resData.message==="Success"){
        console.log(resData);
        localStorage.removeItem('reg1');
        localStorage.removeItem('reg2');
        localStorage.removeItem('reg3');
        localStorage.removeItem('reg4');
        localStorage.removeItem('statereg4');
        localStorage.removeItem('reg5');
        localStorage.removeItem('reg6');
        localStorage.removeItem('reg7');
        global.swal("Success", resData.message, "success")
        .then((value) => {
          window.location.href="/pendaftaran";
        });
        //window.location.href="/pendaftaran";
      }
       
    })*/

    //let regx = Object.assign(reg1, reg2)

    //console.log(JSON.stringify(regx));

    //window.location.href="/pendaftaran5";
    
  }

  handleBack = () => {
    
    localStorage.setItem('reg7', JSON.stringify(this.state));
    window.location.href="/pendaftaran6";
  }


  render() {
    let dataClass = JSON.parse(localStorage.getItem('DATA_PARAMETER')).prmReportSubject;
    let jalur = JSON.parse(localStorage.getItem('reg1'));
    //let state = this.state;
    //console.log(JSON.stringify(dataClass));
    return (
      <div className="content-wrapper">
          <section className="content-header">
              <div className="row">
                  <div className="col-md-12">
                      <div className="box">
                      <div className="divContainter">
                        <Breadcrumb>
                          <Breadcrumb.Item href="/home">Home</Breadcrumb.Item>
                          <Breadcrumb.Item active>Pendaftaran Step 7</Breadcrumb.Item>
                        </Breadcrumb>
                        <Form onSubmit={this.handleSubmit}>

                          <Form.Label className="col-md-12" style={{ margin:5, fontSize: 20, textDecorationLine: 'underline'}} >Nilai Raport Sekolah Asal</Form.Label>
                          <Form.Label className="col-md-12" style={{ margin:5, fontSize: 12}} >*Harap semua nilai diisi, untuk nilai dengan koma menggunakan titik (contoh 85.25)</Form.Label>
                          
                          {dataClass.map((item) =>{
                          //console.log( item);
                          let subject = item.classroom.subject;
                            return(
                              <div key={item.classroom.id}>
                                <Form.Label className="col-md-12" style={{width: 200, margin:5, fontSize: 20}} >{item.classroom.name} </Form.Label>,
                                <hr/>
                                {subject.map((item1) =>{
                                  //const x1 = (state[item.classroom.id+"_"+item1.subject.id+"_1"])? state[item.classroom.id+"_"+item1.subject.id+"_1"] : '1_1_1_23';
                                  //const x11 = x1.split("_")[3];
                                  //console.log(x11)
                                  //let x2 = state[item.classroom.id+"_"+item1.subject.id+"_2"];
                                  return(
                                    <Form.Group controlId="exampleForm.ControlInput1" key={item.classroom.id+"_"+item1.id}>
                                    <div className='rowHorizontal'>
                                      <Form.Label className="col-md-3" style={{width: 200, margin:5}}>{item1.subject.name} </Form.Label>
                                      <Form.Control className="col-md-3"
                                        type="number"
                                        pattern="[0-9]*"
                                        name={item.classroom.id+"_"+item1.subject.id+"_1"}
                                        maxLength="5"
                                        //defaultValue={x11}
                                        min={0}
                                        max={100}
                                        step={0.01} precision={2}
                                        onChange={this.onChange}
                                        style={{width: 120, margin:5}}
                                        placeholder="Semester 1"
                                        required 
                                        />
                                      {jalur.registration_type==='JR'?
                                      <Form.Control className="col-md-3"
                                        type="number"
                                        pattern="[0-9]*"
                                        name={item.classroom.id+"_"+item1.subject.id+"_kkm1"}
                                        maxLength="5"
                                        // defaultValue={80}
                                        min={0}
                                        max={100}
                                        step={0.01} precision={2}
                                        onChange={this.onChange}
                                        style={{width: 120, margin:5}}
                                        placeholder="KKM 1"
                                        required 
                                        />
                                      :null}
                                      <Form.Control className="col-md-3"
                                        type="number"
                                        pattern="[0-9]*"
                                        name={item.classroom.id+"_"+item1.subject.id+"_2"}
                                        maxLength="5"
                                        min={0}
                                        max={100}
                                        step={0.01} precision={2}
                                        onChange={this.onChange}
                                        style={{width: 120, margin:5}}
                                        placeholder="Semester 2"
                                        required 
                                        />
                                      {jalur.registration_type==='JR'?
                                      <Form.Control className="col-md-3"
                                        type="number"
                                        pattern="[0-9]*"
                                        name={item.classroom.id+"_"+item1.subject.id+"_kkm2"}
                                        maxLength="5"
                                        // defaultValue={70}
                                        min={0}
                                        max={100}
                                        step={0.01} precision={2}
                                        onChange={this.onChange}
                                        style={{width: 120, margin:5}}
                                        placeholder="KKM 2"
                                        required 
                                        />
                                        :null}
                                    </div>
                                    </Form.Group>
                                  );
                                })}
                              </div>
                            );
                           })}

                          <Form.Group>
                          <div className='rowHorizontal'>
                            <Button onClick={this.handleBack} block style={{margin:5, height:50}}>Prev</Button>
                            <Button type="submit" block style={{margin:5, height:50}} >Submit</Button>
                          </div>
                          </Form.Group>
                        </Form>
                    </div>


                      </div>
                  </div>
              </div>
          </section>
      </div>
      
    );
  }
}

export default Pendaftaran;